:root {
  --bold-font: 700;
  --primary-font-color: #fff;
  --secondary-font-color: rgb(1,92,165);
  --primary-title-font-color: #0652dd;
  --secondary-title-font-color: rgb(229,49,60);
  
  --primary-background-color: rgb(0,41,106);
  --primary-background-color-gradient: rgb(1,92,165);
  
  --secondary-background-color:#fff;
  --secondary-background-color-gradient:#f4f4f4;

  --font-size: 2rem;
  --transition: all 0.25s ease-in-out;
  --max-section-width: 1024px;

  --gallery-image-width: 100px;
  --gallery-image-height: 119px;

  --main-background-image: url('../images/bg_1.webp');
  --clients-background-image: url('../images/img_clientes.webp');
  --contact-blackgroud-image: url('../images/contact_us.webp');
}

@keyframes rightStart {
  0% {
      transform: translateX(90%);
  }
  
  100%{
      transform: translateX(0);;
  }
}

@keyframes leftStart {
  0% {
      transform: translateX(-90%);
  }
  
  100%{
      transform: translateX(0);;
  }
}

@keyframes popUp {
  0% {
      transform: scale(0.3);
      opacity: 0;
  }

  100%{
      transform: scale(1);
      opacity: 1;
  }
}

@keyframes fade {
  0% {
      opacity: 1;
  }

  100%{
      opacity: 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: sans-serif;
  margin: 0;
  background: linear-gradient(to right, var(--primary-background-color),var(--primary-background-color-gradient));
}

.header {
  background: linear-gradient(to right, var(--primary-background-color),var(--primary-background-color-gradient));
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 
    0 0 0.0625rem 0.0625rem var(--secondary-background-color-gradient),
    0 0 0.125rem 0.125rem var(--secondary-background-color-gradient);
}

.header-container {
  background: linear-gradient(to right, var(--primary-background-color),var(--primary-background-color-gradient));
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 2rem;
}

.header__logo-container{
  margin: 0;
  height: 53px;
  width: 80px;
  overflow: hidden;
  cursor: pointer;
}

.header__logo{
  height: auto;
  width: 100%;
}

.header__menu-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}

.header__menu-item {
  list-style-type: none;
  padding: 0.5rem 0.25rem;
}

.header__menu-nav {
  text-decoration: none;
  font-weight: var(--bold-font);
  font-size: calc(var(--font-size)*0.5);
  color: var(--primary-font-color);
  transition: var(--transition);
}

.header__menu-nav:hover{
  color: var(--secondary-title-font-color);
  text-shadow: 
    0 0 0.125rem var(--primary-title-font-color),
    0 0.125rem 0.125rem var(--secondary-title-font-color);
} 

.header__menu-nav.selected{
  color: var(--secondary-title-font-color);
  text-shadow: 
    0 0.125rem 0.125rem var(--primary-title-font-color),
    0.125rem 0.125rem 0.125rem var(--secondary-title-font-color);
} 

.header__hamburger-icon {
  display: none;
}

.header__hamburger-bar {
  background: var(--primary-font-color);
  display: block;
  width: 30px;
  height: 4px;
  margin: 6px auto;
  transition: var(--transition);
}

.main-container {
  background: var(--main-background-image) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.5rem;
  box-sizing: border-box;
  min-height: 40vh;
  overflow: hidden;
}

.main__title{
  animation: rightStart 1.5s ease-in-out 0s;
  padding: 1rem;
  text-align: center;
  margin: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*1.5);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.main__subtitle{
  animation: leftStart 1.5s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--primary-font-color);
  font-size: var(--font-size);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.main__subtitle-info{
  animation: rightStart 1.8s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*1.2);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--secondary-background-color);
}

.main-container2 {
  background: linear-gradient(to bottom, var(--primary-background-color),var(--primary-background-color-gradient));
  padding: 0.5rem;
  overflow: hidden;
}

.main-container3{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: var(--max-section-width);
  margin: 0 auto;
  box-sizing: border-box;
  gap: 2rem;
  overflow: hidden;
}

.main-image-details-container{
  margin: 0;
  height: 280px;
  width: 275px;
  overflow: hidden;
  border-radius: 0.5rem;
}

.main__image-details{
  height: auto;
  width: 100%;
  border-radius: 0.5rem;
  animation: rightStart 1.5s ease-in-out 0s;
  transition: var(--transition);
}

.main__image-details:hover {
    box-shadow: 
    0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 
    0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}

.main-container-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70%;
}

.main__details{
  padding: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
}

.main-image-container{
  margin: 0;
  width: 400px;
  height: 267px;
  overflow: hidden;
}

.main-image-container.loaded{
  animation: popUp 2s ease-in-out 0s;
}

.main__image{
  height: auto;
  width: 100%;
}

.features {
  background: linear-gradient(to right, var(--secondary-background-color),var(--secondary-background-color-gradient));
}

.features-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  max-width: var(--max-section-width);
  padding: 1.5rem 0.5rem;
  box-sizing: border-box;
  margin: auto;
  overflow: hidden;
}

.features__subtitle{
  animation: rightStart 3s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*1.2);
  text-shadow: 
    0 0 0.125rem var(--secondary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.features__details{
  margin: 0;
  padding: 0;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
}

.features__main-list{
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.features__main-item-image-container{
  margin: 0;
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.features__main-item-image{
  height: auto;
  width: 100%;
}

.features__main-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*0.5);
  gap: 1rem;
  border: 1px solid var(--primary-background-color);
  width: 33%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: var(--transition);
  cursor: pointer;
}

.features__main-item:hover {
  background: linear-gradient(var(--primary-background-color), var(--primary-background-color-gradient));
  color: var(--primary-font-color);
  transform: scale(1.05);
  box-shadow: 
    0 0 0.125rem 0.125rem var(--primary-background-color),
    0 0 0.25rem 0.25rem var(--primary-background-color);
}

.features__main-item:hover .features__main-nav-compatibility{
  color: var(--primary-font-color);
}

.features__main-title{
  margin: 0;
  text-align: center;
  font-weight: var(--bold-font);
  font-size: calc(var(--font-size)*0.7);
  color: var(--secondary-font-color);
}

.features__main-item:hover .features__main-title {
  color: var(--primary-font-color);
}

.features__main-subtitle{
  margin: 0;
  text-align: justify;
}

.features__main-subtitle.cursive{
  font-size: calc(var(--font-size)*0.4);
  font-style: oblique;
}

.features__button-table-toggle{
  font-size: calc(var(--font-size)*0.5);
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
  background-color: var(--secondary-title-font-color);
  border-radius: 0.5rem;
  transition: var(--transition);
  border-color:var(--primary-font-color);
  height: 4rem;
}

.features__button-table-toggle:hover{
  color: var(--secondary-title-font-color);
  background-color: var(--primary-font-color);
  box-shadow: 0 0.125rem 0.125rem 0 var(--secondary-title-font-color);
}

.features__button-table-toggle.toggle-trigger{
  cursor: pointer;
}

.features__table{
  border-collapse: collapse;
  table-layout: fixed;
  border: solid 1px;
  border-radius: 0.5rem;
  font-size: calc(var(--font-size)*0.6);
  padding: 0.1rem;
  margin: 0;
}

.table-hidden-col:nth-of-type(1){
  display: none;  
}

.features-table-container {
  max-height: 300vh;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.features-table-container.hidden {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.features__table-header{
  color: var(--primary-font-color);
  background: linear-gradient(var(--primary-background-color), var(--primary-background-color-gradient));
  padding: 1rem;
  text-align: center;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid;
  margin: 0;
}

.features__table-row{
  cursor: pointer;
}

.features__table-row:nth-child(1n) {
  color: var(--secondary-font-color);
  background: var(--secondary-background-color-gradient);
}

.features__table-row:nth-child(2n) {
  color: var(--secondary-font-color);
  background: var(--secondary-background-color);
}

.features__table-row:hover{
  background: linear-gradient(var(--primary-background-color), var(--primary-background-color-gradient));
  color: var(--primary-font-color);
}

.features__table-data{
  padding: 0.5rem;
  text-align: justify;
}













.features-new {
  background: linear-gradient(to right, var(--secondary-background-color),var(--secondary-background-color-gradient));
}

.features-new-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  max-width: var(--max-section-width);
  padding: 1.5rem 0.5rem;
  box-sizing: border-box;
  margin: auto;
  overflow: hidden;
}

.features-new__subtitle{
  animation: rightStart 3s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*1.2);
  text-shadow: 
    0 0 0.125rem var(--secondary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.features-new__details{
  margin: 0;
  padding: 0;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
}

.features-new__main-list{
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.features-new__main-item-image-container{
  margin: 0;
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.features-new__main-item-image{
  height: auto;
  width: 100%;
}

.features-new__main-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*0.5);
  gap: 1rem;
  border: 1px solid var(--primary-background-color);
  width: 33%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: var(--transition);
  cursor: pointer;
}

.features-new__main-item:hover {
  background: linear-gradient(var(--primary-background-color), var(--primary-background-color-gradient));
  color: var(--primary-font-color);
  transform: scale(1.05);
  box-shadow: 
    0 0 0.125rem 0.125rem var(--primary-background-color),
    0 0 0.25rem 0.25rem var(--primary-background-color);
}

.features-new__main-item:hover .features-new__main-nav-compatibility{
  color: var(--primary-font-color);
}

.features-new__main-title{
  margin: 0;
  text-align: center;
  font-weight: var(--bold-font);
  font-size: calc(var(--font-size)*0.7);
  color: var(--secondary-font-color);
}

.features-new__main-item:hover .features-new__main-title {
  color: var(--primary-font-color);
}

.features-new__main-subtitle{
  margin: 0;
  text-align: justify;
}

.features-new__main-subtitle.cursive{
  font-size: calc(var(--font-size)*0.4);
  font-style: oblique;
}

.features-new_button-table-toggle{
  font-size: calc(var(--font-size)*0.5);
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
  background-color: var(--secondary-title-font-color);
  border-radius: 0.5rem;
  transition: var(--transition);
  border-color:var(--primary-font-color);
  height: 4rem;
}

.features-new__button-table-toggle:hover{
  color: var(--secondary-title-font-color);
  background-color: var(--primary-font-color);
  box-shadow: 0 0.125rem 0.125rem 0 var(--secondary-title-font-color);
}

.features-new__button-table-toggle.toggle-trigger{
  cursor: pointer;
}

.features-new__table{
  border-collapse: collapse;
  table-layout: fixed;
  border: solid 1px;
  border-radius: 0.5rem;
  font-size: calc(var(--font-size)*0.6);
  padding: 0.1rem;
  margin: 0;
}

.features-new-table-container {
  max-height: 300vh;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.features-new-table-container.hidden {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.features-new__table-header{
  color: var(--primary-font-color);
  background: linear-gradient(var(--primary-background-color), var(--primary-background-color-gradient));
  padding: 1rem;
  text-align: center;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid;
  margin: 0;
}

.features-new__table-row{
  cursor: pointer;
}

.features-new__table-row:nth-child(1n) {
  color: var(--secondary-font-color);
  background: var(--secondary-background-color-gradient);
}

.features-new__table-row:nth-child(2n) {
  color: var(--secondary-font-color);
  background: var(--secondary-background-color);
}

.features-new__table-row:hover{
  background: linear-gradient(var(--primary-background-color), var(--primary-background-color-gradient));
  color: var(--primary-font-color);
}

.features-new__table-data{
  padding: 0.5rem;
  text-align: justify;
}










.compatibility{
  background: linear-gradient(to right, var(--primary-background-color),var(--primary-background-color-gradient));
}

.compatibility-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-section-width);
  padding: 1.5rem 0.5rem;
  box-sizing: border-box;
}

.compatibility-table-container{
  display: flex;
  overflow-x: auto;
  align-items: center;
}

.compatibility__subtitle{
  animation: leftStart 5s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*1.2);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--secondary-background-color);
}

.compatibility__details{
  margin: 0;
  padding: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.compatibility__details-important{
  color: var(--secondary-title-font-color);
  font-weight: var(--bold-font);
}

.compatibility__table{
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid;
  border-radius: 0.5rem;
  margin: 0;
}

.compatibility__table-header{
  color: var(--primary-font-color);
  background: var(--primary-background-color);
  padding: 0.3rem;
  text-align: center;
  font-size: calc(var(--font-size)*0.45);
  border: solid var(--primary-font-color) 1px;
  width: 1.5rem;
}

.compatibility__table-header.vertical-header{
  text-align: left;
}

.compatibility__table-row{
  color: var(--secondary-font-color);
  background: var(--secondary-background-color);
}

.compatibility__table-row.row-highlight{
  background: var(--secondary-background-color-gradient);
}

.compatibility__table-data{
  padding: 0.3rem;
  text-align: center;
  font-size: calc(var(--font-size)*0.35);
  border: 1px solid var(--primary-background-color);
  width: 3rem;
}

.compatibility__table-data-footer{
  font-size: calc(var(--font-size)*0.35);
  padding: 0.1rem;
  text-align: left;
}

.compatibility__table-row-footer {
  color: var(--primary-font-color);
  background: var(--primary-background-color);
}

.clients-container {
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  background: var(--clients-background-image) no-repeat;
  background-size: cover;
  overflow: hidden;
}

.clients-container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  margin: 0.5rem auto;
  max-width: var(--max-section-width);
  box-sizing: border-box;
  overflow: hidden;
}

.clients__subtitle{
  animation: leftStart 5s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*1.2);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--secondary-background-color);
}

.clients__details{
  margin: 0;
  padding: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem black;
}

.clients__details-disclaimer{
  margin: 0;
  padding: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*0.3);
  text-align: justify;
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem black;
}


.clients-gallery-container{
  background: var(--secondary-background-color);
  display: grid;
  grid-template-columns: repeat(auto-fit, min(100%, var(--gallery-image-width)));  
  gap: 1rem;
  justify-content: center;
  margin: 0;
  padding: 1.5rem 0.5rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: 
    0 0 0.125rem 0.125rem var(--primary-background-color),
    0 0 0.25rem 0.25rem var(--secondary-background-color);
}

.clients__gallery-image-container{
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  box-sizing: border-box;
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: var(--gallery-image-width);
  max-height: var(--gallery-image-height);
  cursor: pointer;
}

.clients__gallery-image{
  border-radius: 0.5rem;
  height: auto;
  width: 100%;
}

.clients__gallery-image-container:hover, .clients__gallery-image-container.hover {
  transform: scale(1.02);
  box-shadow: 
    0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 
    0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}

.clients__info-container{
  position: absolute;
  border-radius: 0.5rem;
  font-size: calc(var(--font-size)*0.5);
  color: var(--primary-font-color);
  background: var(--primary-background-color);
  transition: var(--transition);
  padding: 0.5rem;
    box-shadow: 
    0 0 0 0.0625rem var(--primary-background-color-gradient),
    0 0 0.125rem 0.125rem var(--primary-background-color-gradient);
  transition: var(--transition);
  opacity: 0;
  pointer-events: none;
}

.clients__gallery-image-container:hover ~ .clients__info-container {
  opacity: 1;
}

.clients__button-contact{
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*0.5);
  font-weight: var(--bold-font);
  height: 3.5rem;
  background-color: var(--secondary-title-font-color);
  border-radius: 0.5rem;
  transition: var(--transition);
  border-color:var(--primary-font-color);
  cursor: pointer;
}

.clients__button-contact:hover{
  color: var(--secondary-title-font-color);
  background-color: var(--primary-font-color);
  box-shadow: 0 0.125rem 0.125rem 0 var(--secondary-title-font-color);
}

.versions {
  background: linear-gradient(to right, var(--secondary-background-color),var(--secondary-background-color-gradient));
}

.versions-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-section-width);
  padding: 1.5rem 0.5rem;
  box-sizing: border-box;
  overflow: hidden;
}

.versions__section-subtitle{
  animation: rightStart 3s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*1.2);
  text-shadow: 
    0 0 0.125rem var(--secondary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.versions__section-details{
  margin: 0;
  padding: 0;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
}

.contact {
  display: flex;
  background: var(--contact-blackgroud-image) no-repeat;    
  background-size: cover;
  min-height: 80vh;
  animation: popUp 1.5s ease-in-out 0s;
}

.contact.unload{
  animation: fade 1.5s ease-in-out 0s;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: var(--max-section-width);
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 1rem auto;
  overflow: hidden;
}

.contact__subtitle{
  animation: leftStart 1.5s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--primary-font-color);
  font-size: var(--font-size);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.contact__details{
  padding: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.contact-inputs-container{
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.contact__button-send {
  color: var(--primary-font-color);
  background: linear-gradient(var(--primary-background-color),var(--primary-background-color-gradient));
  border-radius: 0.5rem;
  transition: var(--transition);
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-top: 1rem;
}

.contact__button-send:disabled {
  pointer-events: none;
}

.contact__button-send:hover {
  background: linear-gradient(var(--primary-background-color-gradient),var(--primary-background-color));
}

.contact__button-cancel{
  color: var(--secondary-title-font-color);
  border-radius: 0.5rem;
  background-color: var(--primary-font-color);
  transition: var(--transition);
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-top: 1rem;
}

.contact__button-cancel:hover{
  color: var(--primary-font-color);
  background-color: var(--secondary-title-font-color);
}

.footer {
  background: linear-gradient(to bottom, var(--primary-background-color),var(--primary-background-color-gradient));
}

.footer-container{
  display: flex;
  margin-top: auto;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
    box-shadow: 
    0 0 0.0625rem 0.0625rem var(--secondary-background-color-gradient),
    0 0 0.125rem 0.125rem var(--secondary-background-color-gradient);
}

.footer__contact-list{
  display: flex;
  gap: 2rem;
  list-style-type: none;
}

.footer__contact-item{
  display: flex;
  align-items: center;
  font-size: calc(var(--font-size)*0.5);
  color: var(--primary-font-color);
  gap: 0.5rem;  
}

.footer__contact-container{
  display: flex;
  flex-direction: column;
}

.footer__contact-title{
  font-weight: var(--bold-font);  
}

.footer__contact-value{
  color: var(--primary-font-color);
  text-decoration: none;
}

.footer__contact-value:hover{
  color: var(--primary-font-color);
  text-shadow: 
    0 0 0.125rem var(--secondary-font-color),
    0 0.125rem 0.25rem var(--primary-font-color);
}

.footer-copyright-container{
  display: flex;
  flex-direction: column;
}

.footer__copyright-info{
  text-align: center;
  font-size: calc(var(--font-size)*0.4);
  color: var(--primary-font-color);
  font-weight: var(--bold-font);
  text-shadow: 
  0 0 0.125rem var(--secondary-font-color),
  0 0.125rem 0.25rem var(--primary-font-color);
  opacity: 0.7;
}

.footer__copyright-date{
  text-align: center;
  font-size: calc(var(--font-size)*0.4);
  color: var(--primary-font-color);
  font-weight: var(--bold-font);
  text-shadow: 
   0 0 0.125rem var(--secondary-font-color),
   0 0.125rem 0.25rem var(--primary-font-color);
   opacity: 0.7;
}

.contact-whatsApp-container {
  position: fixed;
  right: 1%;
  bottom: 5%;
  cursor: pointer;
  transition: var(--transition);
  opacity: 0.7;
  filter: drop-shadow(0 0.125rem 0.313rem rgba(0, 0, 0, 0.7)); 
}

.contact-whatsApp-container:hover {
  transform: scale(1.2);
  opacity: 1;
}

.contact-whatsApp-icon {
  width: 4rem;
  height: 4rem;
}

.error-page{
  background: linear-gradient(to right, var(--secondary-background-color),var(--secondary-background-color-gradient));
}

.error-page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  max-width: var(--max-section-width);
  padding: 1.5rem 0.5rem;
  box-sizing: border-box;
  margin: auto;
}

.error-page-image-container{
  margin: 0;
  width: 200px;
  height: 133px;
  overflow: hidden;
}

.error-page__image{
  width: 100%;
}

.error-page__subtitle{
  text-align: center;
  margin: 1rem 0;
  color: var(--secondary-font-color);
  font-size: var(--font-size);
}

.error-page__details{
  margin: 0;
  padding: 0;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
}

.licences-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-section-width);
  padding: 1.5rem 0.5rem;
  box-sizing: border-box;
}

.licences__subtitle{
  animation: leftStart 5s ease-in-out 0s;
  text-align: center;
  margin: 1rem 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*1.2);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--secondary-background-color);
}

.licences__details{
  margin: 0;
  padding: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size)*0.75);
  text-align: justify;
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
}

.error-page__go-back, .error-page__go-main{
  cursor: pointer;
  text-align: center;
  color: var(--primary-font-color);
  background: linear-gradient(var(--primary-background-color),var(--primary-background-color-gradient));
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: var(--transition);
}

.error-page__go-back:hover, .error-page__go-main:hover{
  color: var(--secondary-font-color);
  background: linear-gradient(var(--secondary-background-color),var(--secondary-background-color-gradient));
}


@media (max-width: 870px) {
  .header-container{
    padding: 0.5rem;
  }

  .header__hamburger-icon {
    display: block;
    cursor: pointer;
  }

  .header__menu-list {
    background: linear-gradient(to right, var(--primary-background-color),var(--primary-background-color-gradient));
    flex-direction: column;
    position: fixed;
    left: 100%;
    top: 5rem;
    width: 100%;
    text-align: center;
    transition: var(--transition);
    height: 100vh;
    gap: 0;
    margin-top: 0;
    justify-content: center;
  }

  .header__menu-list.active {
    left: -35px;
   }

  .header__menu-item {
    margin: 1rem 0;
  }

  .header__hamburger-icon.active .header__hamburger-bar:nth-child(2) {
    opacity: 0;
  }

  .header__hamburger-icon.active .header__hamburger-bar:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }

  .header__hamburger-icon.active .header__hamburger-bar:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

  .main__title{
    font-size: var(--font-size);
  }
  
  .main__subtitle{
    font-size: calc(var(--font-size)*0.7);
  }

  .main__subtitle-info{
    font-size: var(--font-size);
  }

  .main-container {
    min-height: 20vh;
  }

  .main-container3{
    flex-direction: column;
    gap: 0;
  }

  .main-image-details-container{
    width: 200px
  }

  .main-image-container{
    width: 300px;
  }

  .main-container-info{
    max-width: 100%;
  }

  .main__details{
    font-size: calc(var(--font-size)*0.6);
  }
  
  .features__subtitle{
    font-size: var(--font-size);
  }

  .features__details{
    font-size: calc(var(--font-size)*0.6);
  }

  .features__table{
    font-size: calc(var(--font-size)*0.45);
  }

  .features__main-title{
    font-size: calc(var(--font-size)*0.6);
  }
 
  .features__main-list{
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  .features__main-item {
    width: 100%;
  }

  .features__main-item:hover {
    transform: scale(1);
  }



  .features-new__subtitle{
    font-size: var(--font-size);
  }

  .features-new__details{
    font-size: calc(var(--font-size)*0.6);
  }

  .features-new__table{
    font-size: calc(var(--font-size)*0.45);
  }

  .features-new__main-title{
    font-size: calc(var(--font-size)*0.6);
  }
 
  .features-new__main-list{
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  .features-new__main-item {
    width: 100%;
  }

  .features-new__main-item:hover {
    transform: scale(1);
  }



  .compatibility__subtitle{
    font-size: var(--font-size);
  }

  .compatibility__details{
    font-size: calc(var(--font-size)*0.6);
  }
  
  .compatibility-table-container{
    max-width: 100%;
  }

  .compatibility__table-header{
    font-size: calc(var(--font-size)*0.3);
    max-width:min-content;
  }
  
  .compatibility__table-header.vertical-header{
    max-width: fit-content;
  }

  .compatibility__table-data{
    font-size: calc(var(--font-size)*0.3);
  }

  .compatibility__table-data-footer{
    font-size: calc(var(--font-size)*0.28);
  }

  .clients__details{
    font-size: calc(var(--font-size)*0.6);
  }
  
  .clients__subtitle{
    font-size: var(--font-size);
  }

  .clients__info-container{
    font-size: calc(var(--font-size)*0.35);
  }

  .clients-gallery-container{
    grid-template-columns: repeat(auto-fit, min(100%, calc(var(--gallery-image-width)*0.75)));
  }

  .versions__subtitle{
    font-size: var(--font-size);
  }

  .versions__details{
    font-size: calc(var(--font-size)*0.6);
  }

  .versions__main-title{
    font-size: calc(var(--font-size)*0.6);
  }
 
  .versions__main-list{
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  .licences__list{
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  .licences__list-item {
    width: 100%;
  }
  
  .contact__details{
    font-size: calc(var(--font-size)*0.6);
  }
  
  .contact__subtitle{
    font-size: var(--font-size);
  }

  .footer__contact-list{
    flex-direction: column;
    margin: auto;
    gap: 0.75rem;
  }

  .contact-whatsApp-icon {
    width: 3rem;
    height: 3rem;
  }
}