:root{
  --ico_info: url('../images/dlg_info.png'); 
  --ico_error: url('../images/dlg_error.png'); 
  --ico_question: url('../images/dlg_question.png'); 
  --ico_alert: url('../images/dlg_alert.png'); 
  --ico_denied: url('../images/dlg_denied.png'); 
  --dialog-top: 10vh;
}

@keyframes popup {
  0% 
  { opacity: 0; 
    top: 30vh; 
    transform: rotateZ(-30deg);
    scale: 0.7;
  }

  25%
  {
    transform: rotateZ(-15deg);
  }

  50%
  {
    transform: rotateZ(15deg);
  }

  100% 
  { 
    opacity: 1; 
    top: var(--dialog-top); 
  }
}

.dialog-overlay{
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.dialog-overlay.BUTTON_NO_BUTTONS{
  width: auto;
  height: auto;
  left: 50%;
}

.dialog{
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  padding: 0.2rem;
  max-width: 90vw;
  animation: popup 0.5s ease-in-out;
  top: var(--dialog-top);
}

.dialog.TYPE_INFO{
  background: #3399FF;
}

.dialog.TYPE_QUESTION{
  background: #3399FF;
}

.dialog.TYPE_ERROR{
  background: #FF0000;
}

.dialog.TYPE_DENIED{
  background: #FF0000;
}

.dialog.TYPE_ALERT{
  background: #FFFF00;
}

@media (max-width: 768px) {
  .dialog {
    width: 90vw;
  }
}

.dialog__header{ 
  background: rgb(4,41,68); 
  width: 100%;
}

.dialog__header.hidden{ 
  display: none;
}

.dialog__header-text{
  color: white; 
  margin: 0;
  padding: 1rem;
  font-size: calc(var(--font-size)*0.5); 
  text-align: center;
}

.dialog__body{
  display: flex;
  background:#eeeeee;
  padding: 0.5rem;
  min-height: 100px;
  align-items: center;
}

.dialog__body-image{
  height: 92px;
  width: 92px;
}

.dialog__body-image.TYPE_ERROR{
  background: var(--ico_error) no-repeat;
  background-size: 100%;
  background-position: center;
}

.dialog__body-image.TYPE_ALERT{
  background: var(--ico_alert) no-repeat;
  background-size: 100%;
  background-position: center;
}

.dialog__body-image.TYPE_INFO{
  background: var(--ico_info) no-repeat;
  background-size: 100%;
  background-position: center;
}

.dialog__body-image.TYPE_DENIED{
  background: var(--ico_denied) no-repeat;
  background-size: 100%;
  background-position: center;
}

.dialog__body-image.TYPE_QUESTION{
  background: var(--ico_question) no-repeat;
  background-size: 100%;
  background-position: center;
}

.dialog__body-text, .dialog__body-text.TEXT_CENTER{
  color:rgb(4,41,68); 
  margin: 0;
  padding: 0.5rem; 
  font-size: calc(var(--font-size)*0.5); 
  width: 100%;
  text-align: center;
}

.dialog__body-text.TEXT_LEFT{
  text-align: left;
}

.dialog__body-text.TEXT_RIGHT{
  text-align: right;
}

.dialog__footer{ 
  display: flex;
  background:#eeeeee;
  justify-content: center;
  padding: 0.25rem;
  gap: 1rem;
}

.dialog__footer.BUTTON_NO_BUTTONS{
  display: none;
}

.dialog__footer-button{
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 6rem;
  color: rgb(4,41,68);
  background: white;
  border: solid 1px rgb(4,41,68);
  cursor: pointer;
  font-size: calc(var(--font-size)*0.5); 
}

.dialog__footer-button.default{
  color: white;
  border: solid 1px rgb(4,41,68);
  background: linear-gradient(rgb(4,41,68),rgb(1,92,165));
}

.dialog__footer-button:hover{
  color: white;
  background: linear-gradient(rgb(1,92,165), rgb(4,41,68));
  box-shadow: 0 0 0.125rem 0 rgb(1,92,165), 0 0 0.25rem 0 rgb(1,92,165);
}

.dialog__footer-button.default:hover{
  background: linear-gradient(rgb(1,92,165), rgb(4,41,68));
  box-shadow: 0 0 0.125rem 0 rgb(1,92,165), 0 0 0.25rem 0 rgb(1,92,165);
}