.input-container {
  display: flex;
  flex: 0 0 auto;
  transition: all 0.25s;
  width: 100%;
  position: relative;
  padding: 0.5rem;
  box-sizing: border-box;
}

.input-container > input,
.input-container > textarea {
  font-family: sans-serif;
  margin: 0;
  margin-top: 0.75rem;
  padding: 0.5rem;
  width: 100%;
  color: var(--secondary-font-color);
  font-size: calc(var(--font-size) * 0.6);
  text-align: left;
  border: 1px solid var(--secondary-background-color);
  height: calc(var(--font-size) * 0.6 * 1.5);
}

.input-container > textarea {
  height: 80%;
}

.input-container > input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.input-container > input:focus,
.input-container > textarea:focus {
  background-color: var(--secondary-background-color-gradient);
}

.input-container > input::placeholder,
.input-container > textarea::placeholder {
  color: transparent;
}

.input-container > input:placeholder-shown ~ label,
.input-container > textarea:placeholder-shown ~ label{
  transform: scale(1.2) translateY(1.7rem) translateX(0.1rem);
  opacity: 0.75;
  color: var(--secondary-font-color);
}

.input-container > input:focus ~ label, 
.input-container > textarea:focus ~ label {
  transform: none;
  color: var(--primary-font-color);
  text-shadow: 
    0 0 0.125rem var(--primary-background-color),
    0 0.125rem 0.25rem var(--primary-background-color);
    font-weight: 600;
}

.input-container > label {
  margin: 0;
  color: var(--primary-font-color);
  font-size: calc(var(--font-size) * 0.5);
  text-align: left;
  position: absolute;
  padding: 0 0.5rem;
  top: -3px;
  left: 0;
  height: 100%;
  pointer-events: none;
  opacity: 1;
  transform-origin: 0 0;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.row-4 {
  height: 6rem;
}

@media (min-width: 768px) {
  .col-2 {
    width: 16.66666667%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33333333%;
  }
  .col-6 {
    width: 50%;
  }
  .col-8 {
    width: 66.66666667%;
  }
  .col-10 {
    width: 83.33333333%;
  }
};
